import { create } from "@acme/lib/zustand";

interface TDownloadStore {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onCloseModal: () => void;
}

export const useDownloadStore = create<TDownloadStore>((set) => ({
  showModal: false,
  setShowModal: (showModal) => set(() => ({ showModal })),
  onCloseModal: () => set(() => ({ showModal: false })),
}));
